<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      max-width="1100px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ model.id ? 'Edición' : 'Creación' }} Stand</span>
        </v-card-title>
        <v-card-text class="py-0">
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Código"
                  vid="code"
                  rules="required|max:10"
                >
                  <v-text-field
                    class="uppercase"
                    v-model="model.code"
                    type="text"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Código"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider vid="sales" v-slot="{ errors }" name="Categoría" rules="required">
                  <v-select
                    v-model="model.standCategoryId"
                    :error-messages="errors"
                    :items="standCategories"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Categoría"
                  ></v-select>
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor en pesos"
                  vid="copValue"
                  rules="required"
                >
                  <v-currency-field
                    label="Valor en pesos (antes de impuestos)"
                    :error-messages="errors"
                    outlined
                    dense
                    v-model="model.copValue"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0" v-if="appCountry === 'Perú'">
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor en soles"
                  vid="penValue"
                  rules="required"
                >
                  <v-currency-field
                    label="Valor en soles"
                    :error-messages="errors"
                    outlined
                    dense
                    v-model="model.penValue"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Valor en dólares"
                  vid="usdValue"
                  rules="required"
                >
                  <v-currency-field
                    label="Valor en dólares (antes de impuestos)"
                    :error-messages="errors"
                    outlined
                    dense
                    v-model="model.usdValue"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Ancho"
                  vid="width"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.width"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Ancho"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Profundidad"
                  vid="depth"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.depth"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Profundidad"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <validation-provider vid="cornerType" v-slot="{ errors }" name="Tipo de stand" rules="">
                  <v-select
                    v-model="model.cornerType"
                    :error-messages="errors"
                    :items="cornerTypes"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Tipo de Stand"
                  ></v-select>
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0" v-if="model.cornerType">
                <validation-provider
                  v-slot="{ errors }"
                  name="Diferencia Ancho"
                  vid="widthDifference"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.widthDifference"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Diferencia ancho"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0" v-if="model.cornerType">
                <validation-provider
                  v-slot="{ errors }"
                  name="Diferencia Profundidad"
                  vid="depthDifference"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.depthDifference"
                    type="number"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Diferencia Profundidad"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-12 col-md-4 py-0">
                <v-text-field
                  v-model="model.preBookingCompany"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                  label="Empresa de la pre-reserva"
                />
              </div>
              <div class="col-12 py-0">
                <validation-provider
                  vid="description"
                  v-slot="{ errors }"
                  name="Descripción"
                  rules="min:0|max:600">
                  <v-textarea
                    counter="600"
                    outlined
                    clearable
                    type="text"
                    rows="2"
                    :error-messages="errors"
                    clear-icon="mdi-close-circle"
                    label="Escribe una breve descripción de la feria"
                    v-model="model.description"
                  />
                </validation-provider>
              </div>
              <div class="col-12 py-0">
                <v-switch
                  v-model="model.isActive"
                  label="¿Activa?"
                />
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="saveStand"
          >
            Guardar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="onDeleteStand()"
            v-if="model.id"
          >
            Eliminar
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { STAND_CATEGORIES_URL, STAND_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  async created () {
    try {
      this.showLoader()
      await this.getStandCategories()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      appCountry: process.env.VUE_APP_COUNTRY,
      cornerTypes: [
        {
          id: null,
          name: 'Tradicional (Rectangular)'
        },
        {
          id: 'SIZQ',
          name: 'Superior izquierda'
        },
        {
          id: 'SDER',
          name: 'Superior derecha'
        },
        {
          id: 'IIZQ',
          name: 'Inferior izquierda'
        },
        {
          id: 'IDER',
          name: 'Inferior derecha'
        }
      ],
      isActive: false,
      model: {
        placeId: null,
        standCategoryId: null,
        copValue: null,
        cornerType: null,
        code: null,
        depth: null,
        depthDifference: null,
        description: null,
        isActive: null,
        penValue: null,
        usdValue: null,
        width: null,
        widthDifference: null,
        xCoordinate: null,
        yCoordinate: null,
        preBookingCompany: null
      },
      standCategories: []
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.model = {
        placeId: null,
        standCategoryId: null,
        copValue: null,
        cornerType: null,
        code: null,
        depth: null,
        depthDifference: null,
        description: null,
        isActive: null,
        usdValue: null,
        width: null,
        widthDifference: null,
        xCoordinate: null,
        yCoordinate: null,
        preBookingCompany: null
      }
      this.$emit('onClose')
    },
    async getStandCategories () {
      const response = await this.get(STAND_CATEGORIES_URL)
      this.standCategories = response.data
    },
    manageStands () {
      this.$router.push({ name: 'stands', params: { fairId: this.model.fairId, id: this.model.id } })
      this.close()
    },
    open (stand, placeId) {
      this.isActive = true
      this.model.placeId = placeId
      if (!stand || !stand.id) {
        this.model.xCoordinate = 0
        this.model.yCoordinate = 0
      } else {
        this.model = stand
        this.model.isActive = stand.isActive === 1
      }
    },
    saveStand () {
      this.executeWithFormValidation(() => {
        this.model.preBookingCompany = this.model.preBookingCompany === '' ? null : this.model.preBookingCompany
        if (this.model.id) {
          this.update(STAND_URL + '/' + this.model.id, this.model).then(() => {
            this.close()
          })
        } else {
          this.create(STAND_URL, this.model).then(() => {
            this.close()
          })
        }
      })
    },
    onDeleteStand () {
      this.$emit('onDeleteStand', this.model)
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ],
  name: 'PlaceModal'
}
</script>
<style>
.uppercase input {
  text-transform: uppercase
}
</style>
